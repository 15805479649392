.logo_header {
  width: 150px;
}

.logo_success {
  width: 74px;
}

.logo_required {
  width: 36px;
  height: 19px;
}

.image_layout {
  max-width: inherit;
}

.title_direction {
  background-color: #eeeded;
}

.footer {
  margin-top: 90px;
  height: 120px;
  background-color: #000000;
  color: #ffffff;
}

.fixed_footer {
  bottom: 0;
}

.button_end_input {
  border-radius: 0px 4px 4px 0px !important;
  height: 48px;
  box-shadow: none !important;
}

.map_layout {
  border: 1px solid #eeeded;
  width: 100%;
  height: 327px;
  margin: 24px !important;
}

.disabled_title {
  color: #c8c8c8 !important;
}

.button_height {
  height: 48px;
}

.notification_title {
  border: 1px solid #ff483c;
  max-width: 240px !important;
  max-height: 72px !important;
  align-self: center;
}

.divider {
  border: 1px solid #e8e8e8;
  margin: 6px 24px 0px 24px !important;
}

.divider_padding_inner {
  border: 1px solid #e8e8e8;
  margin: 6px 0px 0px 0px !important;
}

.title_button {
  text-transform: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.no_item_list {
  font-style: italic;
  color: #ff483c  !important;
}
