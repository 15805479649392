.landing-page-jit {
  font-family: 'Tahoma' !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  word-break: break-word !important;
}

.landing-page-jit .background-image {
  background: url(/images/subscription/background.png);
}

.landing-page-jit .background-white {
  background: #ffffff !important;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000012;
}

.landing-page-jit .background-grey {
  background: #f8f8f8 !important;
  border-radius: 6px;
}

.landing-page-jit .list-content {
  display: block;
  text-align: center;
}

.landing-page-jit .item-content {
  display: block;
}

.landing-page-jit .item-step {
  padding: 6px 12px;
  border: 2px solid #000000;
}

.landing-page-jit .padding-border-item {
  padding: 5px 11px !important;
  border: 1px solid #000000;
}

.landing-page-jit #geofence,
#family {
  text-decoration: none !important;
}

.landing-page-jit .footer {
  height: 12px;
  background-color: #ffffff;
}

.landing-page-jit .frame {
  width: 40px;
  border: 3px solid;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-bottom: 6px;
}

.landing-page-jit .frame-logo {
  width: 60px;
  border: 5px solid;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
}

.landing-page-jit .frame-button {
  padding: 20px;
  border: 3px solid;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #ffffff;
}

.landing-page-jit .frame-button-clock {
  position: absolute;
  top: 40px;
  width: 100%;
  color: white;
  background: white;
}

.landing-page-jit .button-clock {
  background: black;
  margin: 2px 80px;
  padding: 2px;
  border-radius: 20px;
}

.font-28 {
  font-size: 28px !important;
}

@media screen and (max-width: 320px) {
  .landing-page-jit .auto-font {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  .landing-page-jit .auto-font {
    font-size: 14px;
  }
}

@media screen and (min-width: 376px) {
  .landing-page-jit .auto-font {
    font-size: 14px;
  }
}
