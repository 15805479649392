/* common utility */
::placeholder {
  font-size: 12px;
}

.padding-item-0 {
  padding: 0px 24px !important;
}

.padding-item-6 {
  padding: 6px 24px !important;
}

.padding-content-0 {
  padding: 0px 48px !important;
}

.margin-item-0 {
  margin: 0px 24px !important;
}

.margin-item-18 {
  margin: 18px 24px !important;
}

.margin-item-24 {
  margin: 24px 24px !important;
}

/* Padding */
.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

/* Margin */
.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-30 {
  margin-left: 30px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.mr-24 {
  margin-right: 24px !important;
}


/* Font size */
.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-23 {
  font-size: 23px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-40 {
  font-size: 40px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-600 {
  font-weight: 600;
}

/* Color */
.color-success {
  color: #18c090 !important;
}

.color-info {
  color: #30c0f0 !important;
}

.color-danger {
  color: #ff483c !important;
}

.color-light {
  color: #ffffff !important;
}

.color-dark {
  color: #000000 !important;
}

.color-secondary {
  color: #E8E8E8 !important;
}

.color-disabled {
  color:  #C8C8C8 !important;
}

/* Button color */
.button_color_submit {
  background-color: #FF483C !important;
  color: #ffffff !important;
  height: 48px;
}

.button_color_success {
  background-color: #18C090 !important;
  color: #ffffff !important;
  height: 48px;
}

.button_color_info {
  background-color: #30C0F0 !important;
  color: #ffffff !important;
  height: 48px; 
}

.button_color_danger{
  background-color: #FF483C !important;
  color: #ffffff !important;
  height: 48px;
}

.button_color_disabled{
  background-color: #E8E8E8 !important;
  color: #ffffff !important;
  height: 48px;
}

.button_color_secondary{
  background-color: #E8E8E8 !important;
  color: #000000 !important;
  height: 48px;
}

/* List style type */
.lst-upper-alpha {
  list-style-type: upper-alpha;
}

.lst-lower-alpha {
  list-style-type: lower-alpha;
}

.lst-lower-roman {
  list-style-type: lower-roman;
}

.lst-decimal {
  list-style-type: decimal;
}

/* Text align */
.text-align-end {
  text-align: end;
}
