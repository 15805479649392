@import 'page-layout.css';
@import 'utilities.css';

#root {
  height: 100%;
  font-family: 'Hiragino Kaku Gothic ProN', serif;
  font-size: 16px;
  font-weight: 500;
  /* word-break: break-all; */
}

.font-ios {
  font-family: 'Roboto', sans-serif !important;
}

/* ヘッダーの色は固定*/

th {
  background-color: aliceblue !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

td {
  font-size: 16px !important;
}

/* ヘッダーの色は固定*/

.table_background_color_aliceblue {
  background-color: aliceblue !important;
}

/* ローディング時のマウス設定*/

.load_view {
  position: fixed;
  cursor: wait;

  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  z-index: 1400;
}

/* ローディングアイコン位置*/

.loading {
  position: fixed;
  top: 40%;
  left: 49%;
  z-index: 1400;
}

.cursor_pointer {
  cursor: pointer;
}

.field_size_3 {
  width: 3%;
}

.field_size_5 {
  width: 5%;
}

.field_size_6 {
  width: 6%;
}

.field_size_8 {
  width: 8%;
  max-width: 8%;
}

.field_size_10 {
  width: 10%;
  max-width: 10%;
}

.field_size_12 {
  width: 12%;
  max-width: 12%;
}

.field_size_15 {
  width: 15%;
  max-width: 15%;
}

.field_size_18 {
  width: 18%;
  max-width: 18%;
}

.field_size_20 {
  width: 20%;
  max-width: 20%;
}

.field_size_22 {
  width: 22%;
  max-width: 22%;
}

.field_size_30 {
  width: 30%;
  max-width: 30%;
}

.field_size_35 {
  width: 35%;
  max-width: 35%;
}

.field_size_40 {
  width: 40%;
  max-width: 40%;
}

.field_size_45 {
  width: 45%;
  max-width: 45%;
}

.field_size_50 {
  width: 50%;
  max-width: 50%;
}

.field_size_60 {
  width: 60%;
  max-width: 60%;
}

.field_size_70 {
  width: 70%;
  max-width: 70%;
}

.field_size_75 {
  width: 75%;
  max-width: 75%;
}

.field_size_80 {
  width: 80%;
  max-width: 80%;
}

.field_size_90 {
  width: 90%;
  max-width: 90%;
}

.field_min_size_15 {
  min-width: 15px !important;
}

.field_min_size_30 {
  min-width: 30px !important;
}

.field_min_size_50 {
  min-width: 50px !important;
}

.field_min_size_70 {
  min-width: 70px !important;
}

.field_min_size_100 {
  min-width: 100px !important;
}

.field_min_size_125 {
  min-width: 125px !important;
}

.field_min_size_150 {
  min-width: 150px !important;
}

.field_min_size_200 {
  min-width: 200px !important;
}

.field_min_size_250 {
  min-width: 250px !important;
}

.field_min_size_300 {
  min-width: 300px !important;
}

.field_min_size_350 {
  min-width: 350px !important;
}

.field_min_size_400 {
  min-width: 400px !important;
}

.field_min_size_500 {
  min-width: 500px !important;
}

.field_min_size_600 {
  min-width: 600px !important;
}

.field_min_size_700 {
  min-width: 700px !important;
}

.field_min_size_800 {
  min-width: 800px !important;
}

.field_min_size_900 {
  min-width: 900px !important;
}

.field_min_size_1000 {
  min-width: 1000px !important;
}

.field_min_size_1200 {
  min-width: 1200px !important;
}

.button_color {
  background-color: #333333 !important;
}

.button_color_green {
  background-color: #66cc00 !important;
}

.button_color_red {
  background-color: #dc3545 !important;
}

.button_color_yellow {
  background-color: gold !important;
}

.button_color_info {
  background-color: #17a2b8 !important;
}

.button_magin {
  margin: 3px !important;
}

.button_size {
  width: 124px !important;
}

.checkbox_radio {
  padding: 4px !important;
  color: #333333 !important;
}

.disabled_checkbox_radio {
  padding: 4px !important;
  color: #aaa5a5 !important;
}

.header_color {
  background-color: white;
  color: black;
}

.header_color_mobile {
  background-color: black;
  color: white;
}

.text_align_right {
  display: inline-block;
  text-align: right;
}

.text_align_center {
  display: inline-block;
  text-align: center;
}

.text_align_left {
  display: inline-block;
  text-align: left;
}

.scroll_area_800 {
  max-height: 800px;
  overflow: auto;
}

.scroll_area_700 {
  max-height: 700px;
  overflow: auto;
}

.scroll_area_683 {
  max-height: 683px;
  overflow: auto;
}

.scroll_area_650 {
  max-height: 650px;
  overflow: auto;
}

.scroll_area_600 {
  max-height: 600px;
  overflow: auto;
}

.scroll_area_550 {
  max-height: 550px;
  overflow: auto;
}

.scroll_area_500 {
  max-height: 500px;
  overflow: auto;
}

.scroll_area_400 {
  max-height: 400px;
  overflow: auto;
}

.scroll_area_350 {
  max-height: 350px;
  overflow: auto;
}

.scroll_area_300 {
  max-height: 300px;
  overflow: auto;
}

.scroll_area_280 {
  max-height: 250px;
  overflow: auto;
}

.scroll_area_min_650 {
  min-height: 650px;
}

.scroll_area_min_600 {
  min-height: 600px;
}

.scroll_area_min_500 {
  min-height: 500px;
}

.scroll_area_min_470 {
  min-height: 470px;
}

.scroll_area_min_350 {
  min-height: 350px;
}

.scroll_area_min_300 {
  min-height: 300px;
}

.scroll_area_min_280 {
  min-height: 250px;
}

.card_size {
  max-width: 350px;
  max-height: 300px;
}

.home_card_size {
  min-width: 100%;
  min-height: 100%;
  background-color: aliceblue !important;
}

.card_media_size {
  height: 180px;
  width: 350px;
}

.modal_size_small {
  min-width: 400px;
  width: 30%;
  max-width: 30%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.modal_size_mid {
  min-width: 600px;
  width: 54%;
  max-width: 50%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.modal_size_big {
  min-width: 500px;
  width: 70%;
  max-width: 70%;
  max-height: 85%;
  overflow: auto;
  margin-top: 15px;
}

.product_entry_table {
  width: 100%;
  min-width: 1100px;
  overflow: auto;
}

.search_table {
  width: 100%;
}

.main_card_min_size {
  min-width: 1200px !important;
  overflow: auto;
}

.header_min_size {
  min-width: 1200px !important;
}

.product_list_card_size {
  min-width: 100%;
  min-height: 150px;
  max-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_list_card_minsize {
  min-width: 1200px;
}

.product_checkbox_card_minsize {
  min-width: 130px;
}

.border_bottom {
  border-bottom: 1px solid black;
}

.required_label {
  color: red !important;
}

.required_label::after {
  content: '*';
  margin-left: 5px;
  font-size: small;
}

.page_header {
  border-bottom: 2px solid gray;
}

.font_bold {
  font-weight: bold !important;
}

.font_color_blue {
  color: blue !important;
}

.font_color_mediumblue {
  color: mediumblue !important;
}

.font_color_red {
  color: red !important;
}

.font_color_white {
  color: white !important;
}

.font_color_black {
  color: black !important;
}
.font_color_grey {
  color: gray !important;
}

.master_google_map {
  border: 1px solid #000;
  height: 300px;
  max-width: 500px;
  min-width: 200px;
}

.forward_icon {
  position: relative;
  font-size: 4rem !important;
  top: 30px;
}

.product_group_add_button {
  position: relative;
  background-color: #4a7e17 !important;
  font-size: 12px !important;
  top: 280px;
}

.product_group_delete_button {
  position: relative;
  top: 50px;
}
.product_radio {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.help_text {
  line-height: 45px;
  color: #989898;
}

/* 商品グループ指定のマテリアルアイコンのレイアウト指定 */

.material-icon-center {
  padding-top: 15px;
  padding-left: 5px;
}

/* サジェストの設定(以下がないとおかしくなってた)*/
.MuiContainer-root {
  padding: 0;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0 !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding-right: 14px !important;
  padding-left: 14px !important;
  margin-top: -2px !important;
}

.MuiAutocomplete-popupIndicator {
  display: none !important;
}

.MuiInputBase-multiline {
  height: auto !important;
}

.MuiOutlinedInput-input {
  padding: 10px 10px !important;
}

.MuiOutlinedInput-root {
  height: 48px;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.MuiGrid-spacing-xs-1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiTableCell-body {
  font-size: 16px !important;
}

.MuiDialog-paperScrollPaper {
  display: flex !important;
  max-height: none !important;
  flex-direction: column !important;
}

.MuiDialog-paper {
  overflow-y: hidden !important;
}

.MuiTablePagination-selectIcon {
  top: calc(50% - 12px) !important;
}

.MuiTableRow-root.Mui-selected {
  background-color: rgba(212, 211, 211, 0.603) !important;
}

.width_50p {
  width: 50px;
}
.width_100p {
  width: 100px !important;
}
.width_150p {
  width: 150px !important;
}
.width_200p {
  width: 200px !important;
}
.width_250p {
  width: 250px;
}
.width_300p {
  width: 300px;
}

.width_5 {
  width: 5% !important;
}
.width_7 {
  width: 7.5% !important;
}
.width_10 {
  width: 10% !important;
}
.width_15 {
  width: 15% !important;
}
.width_20 {
  width: 20% !important;
}
.width_30 {
  width: 30% !important;
}
.width_50 {
  width: 50% !important;
}
.width_80 {
  width: 80% !important;
}
.width_85 {
  width: 85% !important;
}
.width_60 {
  width: 60% !important;
}
.width_90 {
  width: 90% !important;
}
.width_100 {
  width: 100% !important;
}

.margin_auto {
  margin: auto;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.error {
  color: red;
  text-align: center;
}

.success {
  color: #18c090;
  text-align: center;
}
